import React from 'react';
import { MainDiv } from '../component/main/MainDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';

export const MainPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<MainDiv />}
  />
);
